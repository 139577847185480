<template>
	<main class="page-container">
		<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
		<div v-if="isMobile" class="box_select is-static">
			<button type="button" id="select_btn" @click="click">커뮤니티</button>
			<ul id="select_list">
				<li v-for="(item, idx) in menus" :key="idx">
					<a :href="item.path">- {{ item.title }}</a>
				</li>
			</ul>
		</div>
		<!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
		<section class="scomm-section">
			<div class="container">
				<!-- 상단 제목 검색 -->
				<div class="scomm-detail-header">
					<h2 class="scomm-detail-header__title">상세페이지 제작 지원사업 커뮤니티</h2>
					<div class="scomm-main-search">
						<form action="#">
							<fieldset>
								<legend class="sr-only">커뮤니티 검색</legend>
								<input type="search" class="scomm-main-search__input" placeholder="커뮤니티를 검색해 보세요!  " />
								<button type="submit" class="scomm-main-search__submit"><i class="icon-scomm-search"></i><span class="sr-only">검색</span></button>
							</fieldset>
						</form>
					</div>
				</div>
				<!-- //상단 제목 검색 -->
				<div class="scomm-detail-flex">
					<!-- s: 왼쪽 영역 -->
					<div class="flex-0">
						<div class="scomm-detail-info__thumb">
							<img src="~@/assets/img/@tmp/tmp_notice_image_02@3x.jpg" alt="사진" />
						</div>
						<!-- 웹접근성 구현 탭 -->
						<Tabs>
							<TabList>
								<Tab>커뮤니티정보</Tab>
								<Tab>나의 활동</Tab>
							</TabList>
							<TabPanels>
								<TabPanel>
									<!-- 커뮤니티정보 -->
									<div class="scomm-detail-info__panel">
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-member"></i>
												<span class="text">멤버수</span>
											</dt>
											<dd>99,999명</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-board"></i>
												<span class="text">게시물 수</span>
											</dt>
											<dd>99,999건</dd>
										</dl>
									</div>
									<!-- //커뮤니티정보 -->
								</TabPanel>
								<TabPanel>
									<!-- 나의 활동 -->
									<div class="scomm-detail-info__panel">
										<!-- 사용자 정보 -->
										<div class="scomm-detail-user">
											<div class="scomm-detail-user__photo">
												<img src="" alt="" />
											</div>
											<div class="scomm-detail-user__text">
												<span class="scomm-detail-user__name">김가치</span>
												<span class="scomm-detail-user__date">2023.02.01 14:32</span>
											</div>
										</div>
										<!-- //사용자 정보 -->
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-user"></i>
												<span class="text">방문</span>
											</dt>
											<dd>99,999회</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-myarticle"></i>
												<span class="text"><a href="">내가 쓴 글 보기</a></span>
											</dt>
											<dd>99,999개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-mycomm"></i>
												<span class="text"><a href="">내가 쓴 댓글 보기</a></span>
											</dt>
											<dd>99,999개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-like"></i>
												<span class="text"><a href="">좋아요 한 글 보기</a></span>
											</dt>
											<dd>99,999개</dd>
										</dl>
									</div>
									<!-- //나의 활동 -->
								</TabPanel>
							</TabPanels>
						</Tabs>

						<a href="#" class="button-default is-rounded is-primary">
							<span class="is-desktop">가입하기</span>
							<span class="is-mobile"><i class="icon-info-join"></i>가입</span>
						</a>
						<nav class="scomm-detail-info__links">
							<ul>
								<li>
									<a href=""><i class="icon-info-home"></i><span>Home</span></a>
								</li>
								<li>
									<a href=""><i class="icon-info-notice"></i><span>공지사항</span></a>
								</li>
								<li>
									<a href=""><i class="icon-info-data"></i><span>자료실</span></a>
								</li>
							</ul>
						</nav>
					</div>
					<!-- e: 왼쪽 영역 -->
					<!-- s: 오른쪽 영역 -->
					<div class="flex-1">
						<!-- 공지사항 -->
						<section class="scomm-detail-notice">
							<div class="scomm-detail-notice__header">
								<h3 class="scomm-detail-notice__title">공지사항</h3>
								<a href="" class="scomm-detail-notice__more">더보기</a>
							</div>
							<div class="scomm-detail-notice__content">
								<ul>
									<li class="scomm-detail-notice__item">
										<a href="" class="scomm-detail-notice__link">
											<span class="scomm-detail-notice__tag">공지</span>
											<strong>공지사항입니다.</strong>
											<em>[25]</em>
										</a>
										<span class="scomm-detail-notice__count">7</span>
									</li>
									<li class="scomm-detail-notice__item">
										<a href="" class="scomm-detail-notice__link">
											<span class="scomm-detail-notice__tag">공지</span>
											<strong>공지사항입니다.</strong>
											<em>[25]</em>
										</a>
										<span class="scomm-detail-notice__count">7</span>
									</li>
									<li class="scomm-detail-notice__item">
										<a href="" class="scomm-detail-notice__link">
											<span class="scomm-detail-notice__tag">공지</span>
											<strong>공지사항입니다.</strong>
											<em>[25]</em>
										</a>
										<span class="scomm-detail-notice__count">7</span>
									</li>
								</ul>
							</div>
						</section>
						<!-- //공지사항 -->
						<!-- 게시글 등록 -->
						<div class="scomm-detail-write">
							<form action="#">
								<fieldset>
									<legend class="sr-only">게시글 등록</legend>
									<textarea name="" id="" cols="30" rows="10" class="textarea"></textarea>
									<div class="scomm-detail-write__footer">
										<div class="flex-l">
											<span class="scomm-detail-write__upload" v-if="previewImage">
												<em class="thumb">
													<img :src="previewImage" />
												</em>
												<button type="button" class="button-delete" @click="cancelUpload">
													<span class="sr-only">이미지 삭제</span>
												</button>
											</span>
											<span class="button-upload-photo" v-else>
												<label>
													<input ref="fileInput" type="file" accept="image/*" @change="onFileSelected()" />
													<i class="icon-detail-photo"></i>
													<span class="sr-only">이미지등록</span>
												</label>
											</span>

											<span class="scomm-detail-write__footer-count">0/1000</span>
										</div>
										<div class="flex-r">
											<button type="submit" class="button-default">등록</button>
										</div>
									</div>
								</fieldset>
							</form>
						</div>
						<!-- //게시글 등록 -->
						<!-- 게시글 -->
						<section class="scomm-detail-list">
							<h3 class="sr-only">게시글 목록</h3>
							<ul class="scomm-detail-articles">
								<li>
									<scommunity-article type="full"> </scommunity-article>
								</li>
								<li>
									<scommunity-article> </scommunity-article>
								</li>
								<li>
									<scommunity-article> </scommunity-article>
								</li>
							</ul>
						</section>
					</div>
					<!-- e: 오른쪽 영역 -->
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';

import ScommunityArticle from '@/components/scommunity/ScommunityArticle';
import PortalPagination from '@/components/PortalPagination';
import { Tabs, Tab, TabList, TabPanels, TabPanel } from 'vue-accessible-tabs';

export default {
	name: 'ScommunityDetail',
	components: {
		ScommunityArticle,
		PortalPagination,
		Tabs,
		Tab,
		TabList,
		TabPanels,
		TabPanel,
	},
	data: () => ({
		totalCount: 100,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 8,
		previewImage: null,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		onFileSelected() {
			let input = this.$refs.fileInput;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.previewImage = e.target.result;
				};
				reader.readAsDataURL(file[0]);
				this.$emit('input', file[0]);
			}
		},
		cancelUpload() {
			this.previewImage = null;
		},
	},
};
</script>
